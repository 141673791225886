*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}
@font-face {
    font-family: Momcake;
    font-weight: bold;
    src: url("/fonts/MOMCAKE.otf") format("opentype");
}
.d-flex {
    display: flex;
}
.align-center {
    align-items: center;
}
.justify-center {
    justify-content: center;
}
.justify-space-between {
    justify-content: space-between !important;
}
.justify-flex-end {
    justify-content: flex-end;
}
.flex-column {
    flex-direction: column;
}
.pull-right {
    float: right;
}
.default-btn {
    font: normal normal bold 1.875rem/2.688rem $fira-sans;
    letter-spacing: 0px;
    padding: 1.125rem 2rem;
    border: none;
    cursor: pointer;
    color: $white;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    background-color: $dark-green;
    &:hover {
        color: $white;
    }
}
.default-btn-gold {
    font: normal normal bold 1.875rem/2.688rem $fira-sans;
    letter-spacing: 0px;
    padding: 1.125rem 2rem;
    border: none;
    cursor: pointer;
    color: $gold;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    border: 2px solid $gold;
    background-color: $white;
    &:hover {
        color: $gold;
    }
}
.white-btn {
    font: normal normal bold 1.875rem/2.688rem $fira-sans;
    letter-spacing: 0px;
    padding: 1.125rem 2rem;
    border: none;
    box-shadow: 0px 3px 6px #00000029;
    border: 2px solid $dark-green;
    border-radius: 1rem;
    cursor: pointer;
    color: $dark-green;
    &:hover {
        color: $dark-green;
    }
}
.gold-btn {
    font: normal normal 600 1.875rem/2.688rem $fira-sans;
    letter-spacing: 0px;
    color: $white;
    padding: 1.125rem 2rem;
    background: $gold 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 3px solid $gold;
    border-radius: 1rem;
    cursor: pointer;
    &:hover {
        color: $white;
    }
}
.gold-btn-transparent {
    font: normal normal 600 1.875rem/2.688rem $fira-sans;
    letter-spacing: 0px;
    color: $gold;
    padding: 1.125rem 2rem;
    background: $white 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    cursor: pointer;
    border: 3px solid $gold;
    border-radius: 1rem;
    &:hover {
        color: $gold;
    }
}
.modal__dialog {
    width: 70rem !important;
    @include respond(phone) {
        width: 90% !important;
    }
}
.modal-btn {
    padding: 5px 10px;
    background: transparent;
    border: 1px solid red;
    color: red;
}
.modal__body {
    width: 100%;
    height: 30rem;
    justify-content: center;
    @include respond(phone) {
        height: 100% !important;
    }
}
.b-right {
    border-right: 2px solid $dark-green;
    height: 20px;
    margin-top: 7px;
}
input:focus {
    box-shadow: none !important;
}
.full-height {
    height: inherit;
}
.input-group-text,
.input-group .border {
    border: 1px solid #0e1810 !important;
    background-color: transparent;
    box-shadow: 0px 12px 24px #00000029;
}
.input-group .border {
    border-left: none !important;
}
$default-font-size: 1.6rem;
html {
    height: 100%;
    // This defines what 1rem is
    font-size: 62.5%; //1 rem = 10px; 10px/16px = 62.5%

    @include respond(tab-land) {
        // width < 1200?
        font-size: 56.25%; //1 rem = 9px, 9/16 = 50%
    }

    @include respond(tab-port) {
        // width < 900?
        font-size: 50%; //1 rem = 8px, 8/16 = 50%
    }

    @include respond(big-desktop) {
        font-size: 75%; //1rem = 12, 12/16
    }
}

body {
    height: 100%;
    box-sizing: border-box;

    @include respond(tab-port) {
        padding: 0;
    }
}

@media screen and (max-width: 425px) {
    .b-right {
        display: none;
    }
    .p-x-0 {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
    .m-x-0 {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}
