.heading-primary {
    font: normal normal bold 6.25rem/8.813rem $fira-sans;
    letter-spacing: 0px;
    color: $black;
}
.heading-secondary {
    font: normal normal 300 2.813rem/3.313rem $fira-sans;
    letter-spacing: 0px;
    color: $black;
}
.heading-ternary {
    font: normal normal 600 1.875rem/2.688rem $fira-sans;
    letter-spacing: 0px;
    color: $black;
}
